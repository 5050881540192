import React, { useCallback, useEffect, useState } from "react";
import {
  AppProvider,
  Card,
  SkeletonBodyText,
  SkeletonPage,
  Layout,
  Frame,
} from "@shopify/polaris";
import {
  Provider as AppBridgeProvider,
  useRoutePropagation,
} from "@shopify/app-bridge-react";
import enTranslations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/dist/styles.css";

import useQueryParam from "../lib/useQueryParam";
import ShopifyGraphQLProvider from "../providers/shopify-graphql";
import MyRouter from "../components/MyRouter";
import { PageProps } from "gatsby";
//import { BrowserRouter, Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { ContextProviderComponent } from "./AppContext";

const AppLayout: React.FC<
  PageProps<
    null,
    null,
    {
      shop?: string;
      host?: string;
    }
  >
> = ({ children }) => {
  // Variables start empty but can be populated via multiple options.
  // First starting with query params, persisted to storage than read from storage
  const [shop, setShop] = useState<string>(null);
  const [host, setHost] = useState<string>(null);

  const [shopFromParam, setShopFromParam] = useQueryParam("shop", null);
  const [hostFromParam, setHostFromParam] = useQueryParam("host", null);

  const [shopFromStorage, setShopFromStorage] = useState<string>(null);
  const [hostFromStorage, setHostFromStorage] = useState<string>(null);

  // Load information from local storage once running in the client
  useEffect(() => {
    setShopFromStorage(localStorage.getItem("shop"));
    setHostFromStorage(localStorage.getItem("host"));
  }, []);

  // When parameters or storage values change set the main value
  useEffect(() => {
    setShop(shopFromParam || shopFromStorage);
  }, [shopFromParam, shopFromStorage]);

  useEffect(() => {
    setHost(hostFromParam || hostFromStorage);
  }, [hostFromParam, hostFromStorage]);

  const [bridgeConfigLoaded, setBridgeConfigLoaded] = useState(false);

  const [apiKey, setApiKey] = useState(process.env.GATSBY_SHOPIFY_API_KEY);
  const [bridgeConfig, setBridgeConfig] = useState<{
    apiKey: string;
    shopOrigin: string;
    host: string;
    forceRedirect?: boolean;
  }>(null);

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { shop, host })
  );

  useEffect(() => {
    // console.log({ apiKey, shop, host });
    // if (bridgeConfigLoaded) {
    //   return;
    // }
    if (apiKey && shop && host) {
      localStorage.setItem("host", host);
      localStorage.setItem("shop", shop);
      setBridgeConfig({
        apiKey,
        shopOrigin: shop,
        host,
        forceRedirect: false,
      });
    } else {
      setBridgeConfig(null);
      // localStorage.removeItem("shop");
      // localStorage.removeItem("host");
    }
  }, [apiKey, shop, host]);
  useEffect(() => setBridgeConfigLoaded(bridgeConfig !== null), [bridgeConfig]);

  return (
    <AppProvider i18n={enTranslations}>
      {/* <BrowserRouter> */}
      {!bridgeConfigLoaded ? (
        <SkeletonPage>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage>
      ) : (
        <AppBridgeProvider config={bridgeConfig}>
          {/* <Frame> */}
            <ContextProviderComponent defaults={{ host, shop }}>
              {/* <MyRouter /> */}
              <ShopifyGraphQLProvider>
                {childrenWithProps}
              </ShopifyGraphQLProvider>
            </ContextProviderComponent>
          {/* </Frame> */}
        </AppBridgeProvider>
      )}
      {/* </BrowserRouter> */}
    </AppProvider>
  );
};

export default AppLayout;
