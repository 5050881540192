const base64EncodeUnicode = (str: string) => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  const utf8Bytes = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function (match, p1) {
      return String.fromCharCode(Number("0x" + p1));
    }
  );

  return btoa(utf8Bytes);
};

export default base64EncodeUnicode;
