import React from "react";

const defaultContextValue = {
  data: {
    // set your initial data shape here
    shop: null,
    host: null,
  },
  set: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ContextProviderComponent extends React.Component<{
  defaults?: {
    host?: string,
    shop?: string
  }
}> {
  constructor(props) {
    const {defaults = {}} = props
    // console.log({ defaults });
    super(props)

    this.setData = this.setData.bind(this);
    this.state = {
      ...defaultContextValue,
      ...defaults,
      set: this.setData,
    };
  }

  setData(newData) {
    this.setState((state) => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { Consumer as default, ContextProviderComponent };
