import * as React from "react";

import { useAppBridge } from "@shopify/app-bridge-react";
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  from,
  ServerError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import base64EncodeUnicode from "../lib/base64Encode";
import { navigate } from "gatsby";

const getQuery = () => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};
// const params = Object.fromEntries(getQuery().entries());
// const params = getQuery();

const ShopifyGraphQLProvider: React.FC<{
  addFetch?: boolean;
}> = ({ children, addFetch = true }) => {
  const app = useAppBridge();
  // console.log(app)
  const urlParams = getQuery();
  const params = urlParams.toString();
  const { session, id, locale } = Object.fromEntries(urlParams.entries());
  const { host, pathname } = window.location;
  const meta = {
    pathname,
    session,
    id,
    locale,
  };
  const encodedMeta = base64EncodeUnicode(JSON.stringify(meta));
  const httpLink = new HttpLink({
    credentials: "include",
    headers: {
      "X-Buds-App-Meta": encodedMeta,
    },
    uri: `/webhooks/shopify/graphql.json`,
    fetch: addFetch
      ? authenticatedFetch(
          app,
          typeof window !== "undefined" ? window.fetch : null
        )
      : undefined,
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if (networkError.hasOwnProperty("result")) {
        const { result } = (networkError as ServerError) || {};
        const { code, url } = result;
        switch (code) {
          case "access-denied":
            navigate("/install/", {
              state: {
                url,
                type: "update",
                backUrl: "/actions/apply-customer-tags/?id=3357751509128",
              },
            });
            break;
        }
      }
    }
  });

  // console.log({ meta, encodedMeta });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, httpLink]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ShopifyGraphQLProvider;
